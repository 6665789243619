<template>
  <v-container
          fill-height
          fluid
          grid-list-xl
  >
    <v-layout
            justify-center
            class="overflow-x-auto"
            wrap
    >
      <template v-if="loaded">
        <data-table ref="datatable"
                    :api-end-point="apiurl"
                    :search="search"
                    :sort-order="sortOrder"
                    :table-name="'Psychologists'"
                    :isDetail="true"
                    :isExport="true"
                    track-by="id"
                    :pre-selected-filters="filters.selectedFilters"
        >
          <template slot="actions">
            <actions :actions="filters.actions" :delete-confirmation-message="'Therapist will be deleted and this action cannot be undone.'"></actions>
          </template>
          <template slot="filters">
            <filters :filters="filters.defaultFilters"></filters>
          </template>
        </data-table>
      </template>

      <!-- delete dialog -->
      <confirmation v-if="deleteDialog" :visible="deleteDialog">
        <template slot="title">
          Are you sure?
        </template>
        <template slot="desc">
          This Therapist will be removed. This action cannot be undone.
        </template>
        <template slot="actions">
          <a class="cancel-model" @click="hideConfirmation">Cancel</a>
          <button class="btn modal-action-button confirmation-delete" @click="removeTherapist">Delete</button>
        </template>
      </confirmation>

    </v-layout>
  </v-container>
</template>

<script>
  import DataTable from "../components/common/dataTable/DataTable";
  import Filters from "../components/common/dataTable/filters/Filters";
  import Actions from "../components/common/dataTable/users/Actions";
  import Confirmation from '../components/common/Confirmation'

  export default {
    name: "Clients",
    components: {DataTable,Filters, Actions, Confirmation},
    data() {
      return {
        apiurl: 'psychologists' ,
        search:{
          placeholder:  'Search by name,email,status,issues,treatments..',
          searchAble: true
        },
        sortOrder: [
          {
            field: 'created_at',
            sortField: 'created_at',
            direction: 'desc'
          }
        ],
        filters:[],
        selected_filters:[],
        loaded: false,
        detailUrl: 'therapist/details/',
        header: true,
        action:{}, //action on the table
        deleteDialog: false,
        therapistID: ''
      }
    },
    created() {
      this.fetchFilters();
    },
    methods:{
      fetchFilters(){
        this.$http.get(this.url+'filters/psychologists').then((res) => {
          if(res.data) {
            this.filters = res.data;
            this.loaded = true;
          } else {
            alert('there is some problem');
          }
        });
      },
      deleteConfirmation(member_id) {
        this.deleteDialog = true;
        this.therapistID = member_id;
      },
      hideConfirmation() {
        this.deleteDialog = false;
      },
      removeTherapist() {
        let self = this;
        let loader = this.$loading.show();
        this.$http.delete(this.url+'user/delete', {
          params: {
            user_id: this.therapistID,
            value: 'deleted'
          }
        }).then(function (response) {
          self.hideConfirmation();
          self.$events.fire('vuetable:reload');
          loader.hide();
          self.$toastr('success',response.data.message,'');
        }).catch(function (error) {
          loader.hide();
          self.$toastr('error', error.response.data.message,'');
        });
      },

    },
    events: {
      'open-details': function(data) {
        this.$router.push(this.detailUrl + data.id);
      },
      'delete-record': function(member) {
        this.deleteConfirmation(member.id);
      },
      'get-table-ref': function(){
        const selectedIds = this.$refs.datatable.$refs.vuetable.selectedTo;
        this.$events.fire('get-selected-data',selectedIds);
      }
    },
  }
</script>
